import { createApp, h,  defineAsyncComponent } from "vue";
const App = defineAsyncComponent(() => import('./App.vue'));
import router from "./router.js";
import { install } from "resize-observer";
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/md-light-deeppurple/theme.css'
import 'primevue/resources/primevue.min.css';
import Vue3Autocounter from 'vue3-autocounter';

import 'primeicons/primeicons.css';
import ScriptX from 'vue-scriptx'


import Ads from 'vue-google-adsense'
import i18nService from "./i18n"



install();

const app = createApp(
{
  computed: {
      ViewComponent() {
          //console.log("path is "+ JSON.stringify(this.$route.fullPath));
          // if(window.location.host.includes("fintrivia.com") ){
          //   return defineAsyncComponent(() => import('./pages/FeedPage.vue'));
          // }
          // else {
            return App;
          //}
          
      },
  },
  render() {
      return h(this.ViewComponent);
  },
  
  mounted() {
    
    if(window.location.host.includes("fintrivia.com") ){
      router.push('/fintrivia');
    }
  }

  
});

app.use(ScriptX)
app.use(Ads.Adsense)
app.use(Ads.InArticleAdsense)
app.use(Ads.InFeedAdsense)
app.use(Vue3Autocounter);
app.use(i18nService.i18n);

app.use(router);

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  next();
});

router.isReady().then(function () {
  app.use(PrimeVue);
  app.mount("#app");
});
