//import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import en from './locales/en.json';
import CookieUtils from './utilities/CookieUtils';
import router from './router';
const DEFAULT_LOCALE = 'en';
const DEFAULT_MESSAGES = en;

class i18nImpl {

  
    static getLocaleFromRoute() {
      //console.log(router.currentRoute.value);
      if( router.currentRoute.value.query.locale){
        return router.currentRoute.value.query.locale;
      }
    }

    static getUserLocale() {
      // console.log('window.navigator.language ', window.navigator.language)
      // console.log('window.navigator.userLanguage ', window.navigator.userLanguage)
      const locale = window.navigator.language ||
        window.navigator.userLanguage ||
        DEFAULT_LOCALE
  
      return locale.split('-')[0];
      
    }
  
  static  getPersistedLocale() {
      let persistedLocale = localStorage.getItem("user-locale")
      if( !persistedLocale ){
        persistedLocale = CookieUtils.getCookie('user-locale');
      }
      return persistedLocale
      
  }
  
    /**
     * checks for persisted locale if none then checks browser settings
     * defaults to default locale.
     */
  static  guessLocale() {
    const queryLocale = i18nImpl.getLocaleFromRoute();
    if( queryLocale) {
      console.log("found locale in route of ", queryLocale);
      return queryLocale;
    }
    const userPersistedLocale = i18nImpl.getPersistedLocale()
    if(userPersistedLocale) {
      console.log("found persisted user locale of ", userPersistedLocale);
      return userPersistedLocale
    }

    const userPreferredLocale = i18nImpl.getUserLocale()

    return  userPreferredLocale;
    

  }

   
    currentLocale = null;
    SUPPORT_LOCALES = ['en', 'fr-CA'];
    i18n = null;

    constructor() {
       
        this.i18n = createI18n(
          { 
            locale: DEFAULT_LOCALE, 
            messages: DEFAULT_MESSAGES,
            fallbackLocale: DEFAULT_LOCALE,
            fallbackWarn: false,
            missingWarn: false
          })
    }


    async initLocale() {
      if( !this.currentLocale){
        let locale = i18nImpl.guessLocale();
       
        locale = locale.split('-')[0];// remove any region for now
        console.log("setting current locale to ", locale);
        this.changeLocale(locale);
          //document.querySelector('html').setAttribute('lang', this.currentLocale)
          //const messages = await this.loadLocaleMessages(this.currentLocale);
        
        
      }
      
      
    }


    // async setDefaultLanguage() {
    //     this.setI18nLanguage(this.defaultLocale);
    // }
    // init(messages){
    //   this.i18n = createI18n({ locale: this.currentLocale, messages: messages })
     
    // }
   


    async changeLocale( locale) {
        
        if (!this.i18n.global.availableLocales.includes(locale)) {
           let messages = await this.loadLocaleMessages(locale)
           if (messages){
            this.i18n.global.setLocaleMessage(locale, messages)

           
            
          }
          else {
            console.log("no messages found for locale "+ locale+" aborting switch.");
            return;
          }
        }
      if (this.i18n.mode === 'legacy') {
          this.i18n.global.locale = locale
      } else {
          this.i18n.global.locale.value = locale
      }
      localStorage.setItem("user-locale", locale);
      document.cookie = "user-locale" + '=' + locale+ '; Path=/; Domain=invrs.com';
      /**
       * NOTE:
       * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
       * The following is an example for axios.
       *
       * axios.defaults.headers.common['Accept-Language'] = locale
       */
      document.querySelector('html').setAttribute('lang', locale)
      
      this.currentLocale = locale;
    

       
    }

    async loadLocaleMessages( locale) {
        // load locale messages with dynamic import
        console.log("attempting to load locale messages for "+ locale);

        let messages = null;
        try {
          messages = await import(`@/locales/${locale}.json`)
        }catch(err){
          console.log("error attempting to load messages for locale "+locale, err)
        }
        if( !messages ){
          // try without region
          let localeNoRegion =  locale.split('-')[0];

          if( !this.i18n.global.availableLocales.includes(localeNoRegion)) {
            console.log("attempting to load locale messages for "+ localeNoRegion);
            try {
              messages = await import(`@/locales/${localeNoRegion}.json`)
            }catch(err){
              console.log("error attempting to load messages for locale "+localeNoRegion, err)
            }
          
            if( !messages) {
              console.log("Cannot load locale messages for "+ localeNoRegion+" falling back to default "+ DEFAULT_LOCALE);
              
            }
          }
        }
       // console.log("got messages ", messages);
        // set locale and locale message
        return messages;
        //return nextTick()
    }

    t(messageKey){
      return this.i18n.global.t(messageKey);
    }
}

const i18nService = new i18nImpl();
export default i18nService;